/* Modal overlay */
.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.75) !important;
  }
  
  /* Modal content */
  .ReactModal__Content {
    position: absolute;
    /* top: 50%; */
    /* left: 50%; */
    right: auto;
    bottom: auto;
    margin-right: -50%;
    /* transform: translate(-50%, -50%); */
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    max-width: 500px;
    width: 80%;
  }
  
  /* Close button */
  .close-button {
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    float: right;
    cursor: pointer;
  }
  
  /* Details list */
  .details-list {
    list-style: none;
    padding: 0;
  }
  
  .details-list li {
    margin-bottom: 10px;
  }
  
  /* Copy button */
  .copy-button {
    margin-left: 10px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f0f0f0;
    cursor: pointer;
  }
  
  .copy-button:hover {
    background-color: #e0e0e0;
  }
  .userbtnwith{
    /* margin-left: 30%; */
    /* margin-right: 30%; */
    border-radius: 10px;
    border: 2px #3041ff solid;
    background-color: #3041ff;
    padding: 8px;
    color: white;
    width: 6rem;
    margin-left: 15px;
    margin-top: 5px;
    margin-bottom: 5px;

}