.partner-box{
    border-radius: 10px;
    border: 2px #0061ca solid;
    padding: 10px;
    width: 40%;
    margin-right: 10px;
    margin-bottom: 10px;
    overflow-y: hidden;

}
.partner{
    display: flex;
    justify-content: center;
    /* flex-direction: column; */
    align-items: center;
    padding-top: 25px;
    flex-wrap: wrap;
}


@media screen and (max-width: 600px) {

    .partner-box h2{
        font-size: 20px;
        word-wrap: break-word;
        width: 100%;
    }
}