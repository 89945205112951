
.topbar-reports{
    display: flex;
    height: 6.5vh;
    align-items: center;
    background-color: #1e2228;
    position: sticky;
    top: 0;
    z-index: 1000;
}
.reports-item-icon{
    color: white;
    font-size: large;
    margin-right: 10px;
    transform: scale(1.5);
    margin-left: 20px;
}
.reports-help-item-icon{
    color: white;
    font-size: large;
    margin-right: 10px;
    transform: scale(1.2);
    margin-right: 20px;
}
.rspace{
    width: 100%;
}
.insights{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    /* height: 100vh; */
}
.insights-dot{
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #f9fd8a;
    border-radius: 25px;
    margin-top: 10px;
    z-index: 1;
    box-shadow: 0px 0px 10px #f9ff48, 0px 0px 10px #f9ff48;

}
.insights-line{
    height: 30vh;
    background-color: #507bd9;
    width: 3px;
    /* transform: rotate(42deg); */
    position: absolute;
    background-image: linear-gradient(to bottom, #507bd9 , #1e3772);
    /* z-index: -1; */
}
.txt-line{
    position: absolute;
    background-color: white;
    /* left: 46%; */
    /* top: 9.5%; */
    height: 2px;
    width: 5vw;
    background-image: linear-gradient(to right, rgb(255, 255, 255) , rgb(56, 56, 56));
}
.dot-text{
    position: absolute;
    color: white;
    /* left: 42%; */
    /* top: 8.5%; */
    /* width: 50vw; */
    font-size: 12px;
    font-family: All;
}
.insight-arr{
    position: absolute;
    background-color: #e8684e7e;
    background-color: #32ba7a7e;
    width: 14%;
    height: 11%;
    border-radius: 10px;
    border: solid 3px #e8684e;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 2px 4px 5px #686868, 0px 0px 6px #4e4e4e;

}
.ins-card-title{
    font-family: Bold;
    font-size: 19px;
    margin-left: 10px;
    color: rgba(233, 233, 233, 0.767);
    margin-bottom: 2px;

}
.ins-card-txt{
    font-family: All;
    font-size: 14px;
    color: rgba(233, 233, 233, 0.734);
    margin-left: 10px;

}
.report-switch{
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -35px;
}
.insights-btn{
    height: 6vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* width: 50%; */
    color: #0094fd;
    flex-grow: 1;
    padding-bottom: 10px;
    padding-top: 30px;
    flex-direction: column;

}
.earnings-btn{
    height: 6vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* width: 50%; */
    color: #ffffff;
    background-color: #1e2228;
    flex-grow: 1;
    padding-bottom: 10px;
    padding-top: 8px;
    flex-direction: column;


}
.select-line{
    height: 3px;
    background-color: #0094fd;
    width: 100%;
    margin-top: 10px;
    border-radius: 10px;
}
.earnings-list{
    /* border: solid 2px white; */
    border-radius: 15px;
    background-color: #ffffff;
    /* height: 15vh; */
    font-family: Box;
    padding: 10px;
    margin: 10px;
    /* width: 100%; */
    font-size: 16px;
    color: #040f24;
    margin-left: -20px;
    width: 46%;
    display: inline-block;
    margin-right: 40px;
}
.date-picker{
    background-color: transparent;
    color: white;
    font-family: All;
    border: solid 2px #0094fd;
    border-radius: 15px;
    padding: 5px;
    margin: 5px;
    padding-left: 20px;
}
.react-datepicker-popper{
    z-index: 1000 !important;

}
.filter-apply-btn{
    background-color: #0094fd;
    color: rgb(0, 0, 0);
    font-family: All;
    border: solid 2px #000000;
    border-radius: 15px;
    padding: 5px;
    margin: 5px;
    padding-left: 10px;
    padding-right: 10px;
}
.e-list-in img{
    height: 55px;
    width: 55px;
    margin-right: 25px;
}
.e-list-in{
    display: flex;
    align-items: center;
    font-family: Box;
    font-size: 18px;
    font-weight: bolder;
    color: #040f24;
}
.e-list-amt{
    display: flex;
    align-items: center;
    font-size: 32px;
    font-family: Bold;
    justify-content: flex-end;
    margin-right: 15px;

}
.e-list-amt span{
    font-size: 14px;
    margin-right: 10px;
    color: #686868;
}






@media screen and (max-width: 600px){
    .earnings-list{
        display: block;
        width: auto;
    }

}