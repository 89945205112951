.create-partner h1{
    margin-left: 20px;
}
.partner-div{
    background-color: azure;
    color: black;
    display: inline-block;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
    width: 90%;
    display: flex;
    /* height: 15%; */
    /* justify-content: space-between; */

}
/* .partner-div input{
    border: 2px #0061ca solid;
    border-radius: 10px;
    padding-left: 5px;
    margin-right: 5px;
    height: 10vh;
} */
.edit-partner-btn{
    background-color: #0061ca;
    color: aliceblue;
    width: 10rem;
    height: 2rem;
    border-radius: 7px;
    border: none;
    margin-right: 5px;
}
.delete-partner-btn{
    background-color: #ca0000;
    color: aliceblue;
    width: 10rem;
    height: 2rem;
    border-radius: 7px;
    border: none;
}