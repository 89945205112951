.listcardkyc{
    background-color: azure;
    color: black;
    display: inline-block;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
    width: 90%;
    display: flex;
    justify-content: space-between;

}
.listcardkyc button{
    border-radius: 10px;
    border: 2px blue solid;
    background-color: transparent;
    padding: 5px;
}