.homebtn{
    background-color: rgb(11, 133, 255);
    color: rgb(255, 255, 255);
    font-weight: bold;
    width: 9rem;
    height: 2rem;
    text-align: center;
    margin: 20px;
    margin-top: 0px;
    margin-right: 0px;
    padding: 20px;
    border-radius: 20px;
    font-size: 18px;
    cursor: pointer;
}