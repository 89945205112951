.Dashboard h1{
    color: azure;
    margin-top: 0px;
    margin-left: 20px;
    padding-top: 10px;
    
}
.filters {
    margin-bottom: 20px;
  }
  
  .filters label {
    margin-right: 10px;
    margin-left: 10px;
  }
  
  .filters input,
  .filters select {
    margin-right: 20px;
    border-radius: 10px;
    background-color: transparent;
    color: azure;
    border: 2px #406dff solid;
    padding: 5px;
  }
  table, th, td {
    border: 1px #ffd190 solid;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 5px;
    text-align: center;
  }

  table {
    width: 95%;
    border-collapse: collapse;
  }
  