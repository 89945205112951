.login-container{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 70vh;
    align-items: center;
}
.logincard{
    background-color: rgb(42, 42, 57);
    padding: 25px;
    border-radius: 20px;
    width: 15rem;
}
.input-container{
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.input-container input{
    margin-top: 5px;
    border: 2px #0061ca solid;
    border-radius: 20px;
    background-color: transparent;
    font-size: 14px;
    padding: 5px;
    color: white;
}
.login-btn{
    margin-top: 10px;
    width: 100%;
    border-radius: 20px;
    background-color: #0061ca;
    border: none;
    padding: 8px;
    color: white;
    margin-top: 15px;
}